<template>
  <section class="content">
    <div class="card card-primary">
    <div class="card-body">
    <div class="row">
        <div class="col-12 d-flex justify-content-end mb-2">
            <div class="row form-horizontal">
                <div class="col-auto">
                    <label class="control-label my-2 ml-2">
                        <h6>Filter Tanggal</h6>
                    </label>
                </div>
                <div class="col-auto">
                    <div class="input-group mb-1">
                        <input type="text" ref="daterange" class="form-control" style="border-right: 0" />
                        <div class="input-group-append">
                            <div class="input-group-text" style="background-color: #fff">
                                <span><span class="fa fa-calendar"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <div class="btn-group" ref="toolbar">
                        <button type="button" data-action="read" class="btn btn-default"><i
                        class="fa fa-sync-alt mr-1"></i></button>
                        <button
                      type="button"
                      :disabled="downloading"
                      v-on:click="downloadXlsx"
                      data-action="export-xlsx"
                      class="btn btn-default"
                    >
                      <i class="fa fa-download"></i> Download
                    </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="table-responsivex">
      <table class="table table-hover" ref="tblrekap">
        <thead>
          <tr>
            <th>SHIPPER</th>
            <th>TOTAL NILAI COD</th>
            <th>BIAYA COD</th>
            <th>NET NILAI COD</th>
            <th>COD DALAM PROSES</th>
            <th>COD RETUR</th>
            <th>COD TERKIRIM</th>
            <th>COD TERBAYAR</th>
            <th>COD BELUM TERBAYAR</th>
            <th>ONGKIR REGULER + ONGKIR RETUR</th>
          </tr>
        </thead>
        <tbody @click="handleClick"></tbody>
      </table>
    </div>
    </div>
    </div>
  </section>
  <!-- /.content -->
</template>
<script>
import { createTable, authFetch, formatCurrency } from "@/libs/hxcore";
import Daterangepicker from "daterangepicker";
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";
import $ from "jquery";
import moment from "moment";

export default {
  name: "Financial Shipper",
  data() {
    return {
        errors: [],
        method: "",
        dateStart: '',
        dateEnd: '',
        dt1: moment().day(-31),
        dt2: moment(),
        filter: {},
        //   roles: "",
        //   form: {
        //     name: "",
        //     email: "",
        //     pass: "",
        //   },
    };
  },
  created: function () {
    this.roles = this.$route.meta.roles;
    console.log("load initial data", this.$route);

    this.dt1 = moment().day(-31);
    this.dt2 = moment();
    this.dateStart = this.dt1.format('YYYY-MM-DD');
    this.dateEnd = this.dt2.format('YYYY-MM-DD');
    
    this.filter.dt1 = this.dateStart;
    this.filter.dt2 = this.dateEnd;

    // if (!this.filter.dt1 && !this.filter.dt2) {
    //     this.filter.dt1 = this.dateStart;
    //     this.filter.dt2 = this.dateEnd;
    // }
    this.onDate();
  },
  methods: {
    onDate() {
        this.table.api().ajax.reload();
    },
    downloadXlsx: function (e) {
        const self = this;
        self.processing = true;
        var filter = JSON.parse(JSON.stringify(self.filter));
            filter.category = $("select[name=category]").val();
            filter.q = $("input[name=q]").val();

        var data = Object.keys(filter)
            .map((key) =>
                encodeURIComponent(key) + "=" + (filter[key] === false ? 0 : encodeURIComponent(filter[key]))).join("&");
        authFetch("/report/finance_shipper/excell", {
            method: "POST",
            Headers:"Access-Control-Allow-Origin, http://localhost:3000",
            body: data,
        }).then((response) => response.blob())
        .then((blob) => {
        setTimeout(() => {
            self.processing = false;
        }, 1000);
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = "data.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
        });
    },
    submitForm: function (ev) {
      const e = this.$refs;
      var allData = { group: this.form };
      var urlSubmit = "/shipper";
      if (this.method == "PUT") urlSubmit = "/shipper/" + this.form.id;

      authFetch(urlSubmit, {
        method: this.method,
        body: JSON.stringify(allData),
      })
        .then((res) => {
          if (res.status === 201) {
          } else if (res.status === 400) {
          }
          return res.json();
        })
        .then((js) => {
          this.errors = [];
          if (!js.success) {
            console.log(js.details);

            for (var key in js.details) {
              if (js.details.hasOwnProperty(key)) {
                this.errors.push(js.details[key]);
              }
            }

            return;
          }
          this.table.api().ajax.reload();
          $(e.formDialog).modal("hide");
        });

      ev.preventDefault();
    },
    handleClick(e) {
      // if (e.target.matches(".link-role")) {
      //   this.$router.push({ path: "/saldo/shipper/" + e.target.dataset.id });
      //   return false;
      // }
      if (e.target.matches(".link-finance")) {
        // console.log(e.target.dataset.param);
        // console.log(e.target.dataset.id);
        // console.log(this.filter.dt1);
        // console.log(this.filter.dt2);
        if (e.target.dataset.param == 'totalcod') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { totalcod: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'biaya_cod') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { biaya_cod: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'cod_retur') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { cod_retur: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'cod_terkirim') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { cod_terkirim: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'totalterbayar') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { totalterbayar: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'belumterbayar') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { belumterbayar: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        } else if (e.target.dataset.param == 'totaldlmproses') {
          this.$router.push(
            {
            name: 'Payment BOSCOD',
            params: {filter: { totaldlmproses: 1, dt1: this.filter.dt1, dt2: this.filter.dt2, shipper_id: e.target.dataset.id }}
            });
        }
				return false;
			}
    }
  },
  mounted() {
    const e = this.$refs;
    const self = this;

    new Daterangepicker(
        this.$refs.daterange,
        {
            startDate: this.dt1,
            endDate: this.dt2,
            locale: {
              format: 'DD/MM/YYYY'
            },
        },
        function (d1, d2) {
            self.dateStart = d1.format("YYYY-MM-DD");
            self.dateEnd = d2.format("YYYY-MM-DD");
            self.filter.dt1 = self.dateStart;
            self.filter.dt2 = self.dateEnd;
            self.onDate();
        }
    );

    this.table = createTable(e.tblrekap, {
      title: "",
      roles: ["read"],
      ajax: "/payment/admin/finance",
      scrollX: true,
      processing: true,
      toolbarEl: e.toolbar,
      frame: false,
      selectedRowClass: "",
      language: {
        processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
      paramData: function (d) {
        var filter = JSON.parse(JSON.stringify(self.filter));
        for (const key in filter) {
            d[key] = filter[key] === false ? 0 : filter[key];
        }
      },
      columns: [
        { data: "nama" },
        {
          data: "total_cod",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="totalcod" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "biaya_cod",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="biaya_cod" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "net_cod",
          width: "90px;",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
        {
          data: "cod_proses",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="totaldlmproses" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "codretur",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="cod_retur" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "cod_terkirim",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="cod_terkirim" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "cod_terbayar",
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="totalterbayar" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "cod_belum_terbayar",
          sortable: false,
          width: "90px;",
          render: function (data, type, row, meta) {
            // return "Rp " + formatCurrency(data);
            return '<a class="link link-finance text-info" style="cursor:pointer;" data-param="belumterbayar" data-id="' +
							row.id +
							'">' +
							"Rp " + formatCurrency(data) +
							"</a>";
          },
        },
        {
          data: "ongkir_reguler_retur",
          width: "90px;",
          render: function (data, type, row, meta) {
            return "Rp " + formatCurrency(data);
          },
        },
      ],
      filterBy: [0],
      rowCallback: function (row, data) {
        // $("td:eq(0)", row).html(
        //   '<a class="link link-role" data-id="' +
        //     data.user_id +
        //     '" style="cursor:pointer;">' +
        //     data.nama +
        //     "</a>"
        // );
        // $("td:eq(3)", row).html("Rp " + formatCurrency(data.total_saldo));
      },
			initComplete: function () {
				$('.loading-overlay').removeClass('show');
			},
    });
  },
};
</script>